.scroll-box {
  display: grid;
  grid-template-columns: 10px 1fr 10px;

  position: relative;
  width: 100%;
  /* overflow: hidden; */
}

.scroll-box > * {
  grid-column: 2 / -2;
}
.scroll-box > .full {
  grid-column: 1 / -1;
}

.scroll-box__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  /* -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; */
}

.scroll-box__wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-box__container {
  height: 100%;
  display: grid;
  /* grid-gap: calc(var(--gutter) * 2); */
  align-items: center;

  grid-template-columns: repeat(var(--total), calc(5.625rem - 0.75rem));
  padding: 0 0px;

  grid-auto-flow: column;
  /* grid-auto-columns: max-content; */
  padding-bottom: calc(0.75 * var(--gutter));
  max-width: 100%;
  min-width: 200px;
  justify-content: start;
}

.scroll-box-avatars-container {
  height: 100%;
  display: grid;
  grid-gap: 0.25rem;
  align-items: center;

  grid-template-columns: repeat(5.625rem);
  padding: 0 0px;

  grid-auto-flow: column;
  /* grid-auto-columns: max-content; */
  padding-bottom: calc(0.75 * var(--gutter));
  max-width: 100%;
  min-width: 100%;
  justify-content: start;
}

.scroll-box__item {
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  /* justify-content: flex-start;
  position: relative; */
  align-items: center;
  /* overflow: hidden; */
  flex-wrap: nowrap;
  align-content: flex-start;
}

@media (max-width: 40rem) {
  .scroll-box {
    display: grid;
    grid-template-columns: 0px 1fr 10px;
    /* height: 100px; */
    max-width: calc(100vw - 0.75rem);
    position: relative;
    width: 100%;
    overflow: auto;
  }
}

@media (min-width: 40.1rem) {
  .scroll-box {
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    /* max-width: 200px; */
    position: relative;
    width: 100%;
    overflow: hidden;
    /* background-color: blue; */
  }
}

/* .scroll-box__item:hover {
  color: dodgerblue;
  overflow: visible;
  z-index: 100;
  transition: 0.2s ease-out;
  transform: translate3d(0%, -5%, 0rem);
} */

/* .scroll-box__item:hover .item {
  z-index: 100;
} */

/*.item {
  position: absolute; */
/* background: white; */
/* width: 100%;
} */
/* 
.scroll-box__item:hover .item {
  color: black;
  font-size: 1.2rem;
  overflow: visible;
  z-index: 100;
  transition: 0.2s ease-out;
  transform: translate3d(0%, 40%, 0rem);
}

@media screen and (min-width: 967px) and (max-width: 1127px) {
  .scroll-box__item {
    flex-basis: calc(100% / 3);
  }
}

@media screen and (max-width: 967px) {
  .scroll-box__item {
    flex-basis: calc(100% / 2);
  }
}

.scroll-box__item_mobile {
  display: flex;
  flex-basis: calc(100% / 4);
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 50%;
  background-color: lightsalmon;
  align-items: center;
  height: 3.3rem;
  width: 3.3rem;
  overflow: hidden;
  flex-wrap: nowrap;
  align-content: flex-start;
} */
