
.utils-label{
  text-align: left;
  font-weight: bold;
  font:inherit;
  font-size: inherit;
}
.utils-label[type="false"]{
 opacity:0.5;
}

.utils-input {
  min-width: 80%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  margin-bottom: 0.25rem;
  font:inherit;
  font-size: inherit;
}

.utils-input-noMrgn {
  min-width: 80%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  font:inherit;
  font-size: inherit;
}

.required:after {
  content:" *";
  color: red;
}

.activefalse:after {
  opacity:0.5;
}

.description{
  text-align: left;
  font-style:italic;
  margin-top:0;
  margin-bottom: 0.25rem;
}