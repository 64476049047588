.slider-container {
  width: 100%;
  position:relative;
}

.slider-style{
  position: absolute;
  margin-top: 0;
  padding-top: 0;
  right: 0;
  width: 8rem
}

.range-slider {
  -webkit-appearance: none;
  background-color:darkslategray;
  width: 100%;
  height: 0.15rem;
  border-radius: 0.15rem;
  outline: none;
  padding: 0;
  margin-top: 1rem;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  background-color:rgb(1, 169, 169);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background .15s ease-in-out;
  z-index: 999;
}

.range-slider::-moz-range-thumb {
  background-color:rgb(0, 169, 169);
  width: 1rem;
  height: 1rem;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background .15s ease-in-out;
  z-index:999
}

.range-slider-value {
  border-radius: 0.2rem;
  font-size: 0.9rem;
}

.range-slider-value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: '';
}

.range-min-max-values {
  position: absolute;
  font-size: 0.9rem;
  color:black;
  /* text-align: center;
  width: 90%;
  margin-left: 50px; */
}

.range-min-max-values[type="left"] {
  position: absolute;
  left:0;
  /* top:0.5rem; */
  font-size: 0.9rem;
  color:black;
}

.range-min-max-values[type="right"] {
  position: absolute;
  right:0;
  /* top:0.5rem; */
  font-size: 0.9rem;
  color:black;
}