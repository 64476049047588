:root {
  --gutter: 2px;
}

.hs {
  width: 100%;
  /* max-width: 250px; */
  /* padding-right: 10px; */
  align-items: center;
  border-radius: 8px;
  /* margin-left: 10px;
  margin-right: 10px;
  
  /* display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px; */
  /* grid-template-rows: minmax(80px, 1fr); */
  /* grid-template-columns: repeat(6, calc(50% - 40px)); */

  /* grid-template-rows: minmax(80px, 1fr);

  grid-auto-flow: column;
  grid-auto-columns: calc(25% - var(--gutter) * 2.5); */
  /* overflow-x: scroll; */
  /* padding-bottom: calc(0.75 * var(--gutter));
  margin-bottom: calc(-0.25 * var(--gutter)); */
}
.hs-pics {
  width: 95%;
}

.hs:before,
.hs:after {
  content: "";
  width: 10px;
}

/* @media (max-width: 799px) {
  .hs { */
/* grid-template-rows: max-content; */
/* display: flex;
  }
} */
/* 
@media (min-width: 800px) {
  .hs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
  }
} */

/* @media (max-width: 640px) {
  .hs {
    min-width: 200px;
    max-width: 400px;
  }
} */

/* @media (max-width: 500px) {
  .hs {
    min-width: 200px;
    max-width: 300px;
  }
}

@media (max-width: 400px) {
  .hs {
    min-width: 200px;
    max-width: 200px;
  }
} */

/* .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(107, 42, 42);
  border-radius: 8px;
  height: 50px;
} */

/* .selected {
  background: rgb(180, 98, 98);
} */

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.carousel-scroll-box {
  display: grid;
}
