.UI-new-msg-count-on {
  position: sticky;
  bottom: 6%;
  float: right;
  color: var(--unread_topic_indicator_text_color);
  background-color: var(--unread_topic_indicator_background_color);
  border-radius: 50%;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.85rem;
  opacity: 0.5;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  z-index: 100;
}

.UI-new-msg-count-off {
  display: none;
}

.UI-new-msg-button-text {
  color: white;
  font-size: 100%;
  font-style: normal;
}

.UI-main-screen-fab-area {
  display: flex;
  flex-direction: flex-row;
  border-top: 3px solid var(--main_header_separator_line_color);
  align-content: center;
  justify-content: space-evenly;
  min-width: calc(100% - 0.625rem);
  max-width: calc(100%);
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
  height:44px;
}

.UI-main-screen-fab {
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  transition: background var(--speed);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.UI-main-screen-fab:hover {
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
}