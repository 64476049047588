:root {
  --basefont: "Roboto";
  --baseweight:"400";
  /* --basefont: "Open Sans";
  --baseweight:"500"; */
}

html {
  font-size: 14px;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: var(--basefont);
  font-weight: var(--baseweight);
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 480px) {
  html {
    font-size: 16px;
  }
}
