:root {
  --speed: 500ms;
}
.test {
  width: fit-content;
}

.toggle-switch[type="checkbox"] {
  /* display: none; */
}

.toggle-switch {
  position: relative;
  display: inline-block;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  /* background-color: #ccc; */
  /* border-radius: 25px; */
  /* border: 1px solid black; */
  /* border-style: solid; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: "#000000" 0.2s ease;
  /* transition: background-color 0.2s ease; */
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 4px;
  top: 1px;
  aspect-ratio: 1;
  height: 80%;
  background-color: #ffffff;
  border-radius: 50%;
  /* border: 1px solid black; */
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(calc(100%));
  /* background-color: #ffffff; */
  /* border: 1px solid black; */
}
.toggle-switch input[type="checkbox"]:not(:checked) + .switch::before {
  transform: translateX(0%);
  background-color: #ffffff;
  /* border: 1px solid black; */
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  /* background-color: #64dd17; */
  /* border: 1px solid black; */
}

.label-cards {
  display: flex;
  align-items: center;
  /* padding-left: 8px; */
}

.combo-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;
}

.combo-cards-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.UI-persona-screen {
  /* display: flex;
  flex-direction: column; */
  position: absolute;
  transform: translateY(250px);
  width: 100%;
  /* justify-content: center;
    align-items: flex-start;
    overflow-y: scroll; */
}

.UI-header {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 200px;
  justify-content: space-evenly;
}

.UI-body {
  position: relative;
  margin-left: 50px;
  width: 300px;
  border-left: 4px solid lightseagreen;
  border-right: 4px solid lightseagreen;
}

.UI-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.UI-cards-noswitch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .persona-screen-layout {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 120px);
  transform: translateY(120px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid black;
  overflow-y: scroll;
  padding-right: 80px;
  overflow: hidden;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 1;
}

@media (min-width: 80rem) {
  .persona-screen-layout {
    grid-column-start: 2;
    grid-column-end: 2;
  }
} */

.dropdown-txtinp {
  position: absolute;
  width: 220px;
  background-color: var(--menu_background_color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--menu_border_color);
  border-radius: var(--border_radius_large);
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  z-index: 999;
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  animation: growDown var(--speed) ease-in-out forwards;
  opacity: "1";
}

.menu-txtinp {
  width: 100%;
  display: block;
}

.menu-item-txtinp {
  color: var(--menu_text_color);
  height: 16px;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  border-radius: var(--border_radius_medium);
  margin-left: 48px;
  /* transition: background var(--speed); */
  padding: 0.1rem;
  cursor: pointer;
  white-space: nowrap;
}

.menu-title-txtinp {
  cursor: pointer;
  font-size: 1rem;
  color: black;
  width: 300px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  padding-left: 4px;
  border: 1px solid black;
  height: 40px;
  border-radius: var(--border_radius_small);
}

.menu-text-txtinp {
  margin-left: 0.5rem;
}

.form-content {
  /* height: 100%; */
  height: calc(70vh - 150px);
  overflow: auto;
}
